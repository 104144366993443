
import {
  ErrorMessageEntities,
  ModalMessageEntities,
  OptionsClass
} from "@/domain/entities/MainApp";
import { Options, Vue } from "vue-class-component";
import Geolocation from "../geolocation/index.vue";
import Repetable from "../repeatable/index.vue";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { ClientController } from "@/app/ui/controllers/ClientController";
import { CnManifestController } from "@/app/ui/controllers/CnManifestController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { debounce } from "lodash";
import { PickupScheduleFormApiRequest } from "@/data/payload/api/PickupScheduleApiRequest";
import {
  CLIENT_ERROR,
  formatDate,
  formatDateNormal,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import { PickupScheduleController } from "@/app/ui/controllers/PickupScheduleController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { PickupSchedule } from "@/domain/entities/PickupSchedule";
import {
  configDates,
  configDay
} from "@/app/infrastructures/misc/common-library/modules/Date";
import { DistrictData } from "@/domain/entities/Location";
import { ApiStateData } from "@/domain/entities/Api";
import { CourierManagementController } from "@/app/ui/controllers/CourierManagementController";
import { ApiRequestCourierVehicleList } from "@/data/payload/api/CourierManagementApiRequest";

@Options({
  components: {
    Geolocation,
    Repetable
  }
})
export default class ScheduleConfigForm extends Vue {
  controller = PickupScheduleController;
  accountController = AccountController;
  cnManifestController = CnManifestController;
  clientController = ClientController;
  locationController = LocationController;
  courierManagementController = CourierManagementController;

  isTransportTypeSelectOpen = false;
  isLoadingSubmit = false;
  modalMessageEntities = new ModalMessageEntities();
  modalErrorMessageEntities = new ErrorMessageEntities();
  showModalSuccess = false;
  showModalFailed = false;
  showModalConfirm = false;
  pickupScheduleData = new PickupSchedule();
  modalConfirmEntities = new ModalMessageEntities({
    image: "badge-confirmation-general",
    title: "Simpan perubahan konfigurasi jadwal Pick Up?",
    message: "Pastikan data yang Anda masukkan sudah benar."
  });
  apiDetailData = new ApiStateData();

  form = {
    name: "",
    clientId: new OptionsClass({}),
    cityId: "",
    districtId: new OptionsClass({}),
    address: "",
    geoloc: "",
    courierNote: "",
    transportType: "",
    serviceCode: [] as string[],
    estimateTotalKoli: "",
    estimateTotalTonase: "",
    start: "",
    end: "",
    time: "",
    cityTimezone: "",
    intervalWeek: 1,
    repeatDays: [] as string[]
  };

  letterCodeOptions = [new OptionsClass({ name: "-", value: "" })];
  transportTypeOptions = [
    new OptionsClass({ name: "Pilih tipe armada", value: "" })
  ];
  isLoadingAutofill = false;
  startDate = this.form.start;

  get isEditPage() {
    return this.$route.name === "pickup-scheduled-edit";
  }

  get isClientBranch() {
    return this.accountController.accountData.isClientBranchAccount;
  }

  get isClientParent() {
    return this.accountController.accountData.isClientParentAccount;
  }

  get isAdmin() {
    return this.accountController.accountData.isInternalAccount;
  }

  get parentId() {
    return this.accountController.accountData.account_type_detail.id;
  }

  get minDate() {
    return new Date(new Date().setDate(new Date().getDate() + 1));
  }

  get clientOptions() {
    if (this.isClientParent) {
      const options = this.clientController.clientData.data.map(client => {
        return new OptionsClass({
          name: client.companyName,
          value: client.id.toString()
        });
      });

      return options;
    }
    if (this.isAdmin) {
      const options = this.clientController.clientMappingData.map(client => {
        return new OptionsClass({
          name: client.clientName,
          value: client.clientId.toString(),
          code: client.clientOriginCity.split("-")[0]
        });
      });

      return options;
    }

    return [];
  }

  get districtOptions() {
    return this.locationController.districtData.districtData.map(item => {
      return new OptionsClass({
        name: item.districtNameCityName,
        value: item.code,
        code: item.timezone
      });
    });
  }

  get isEndDateMoreThanStartDate(): boolean {
    return this.form.end
      ? new Date(this.form.end) >= new Date(this.form.start)
      : true;
  }

  get isFormValid(): boolean {
    return (
      !!this.form.name &&
      !!this.form.clientId.value &&
      !!this.form.districtId.value &&
      !!this.form.cityId &&
      !!this.form.address &&
      !!this.form.geoloc &&
      !!this.form.transportType &&
      !!this.form.serviceCode.length &&
      !!this.form.start &&
      !!this.form.end &&
      !!this.form.time &&
      !!this.form.repeatDays.length &&
      this.isEndDateMoreThanStartDate
    );
  }

  get pickupScheduleSuccessModalData() {
    return {
      transportType: this.form.transportType,
      serviceCodes: this.form.serviceCode.join(", "),
      start: this.form.start
        ? formatDateNormal(this.form.start, "D MMMM YYYY")
        : "-",
      end: this.form.end ? formatDateNormal(this.form.end, "D MMMM YYYY") : "-",
      pickupInterval: this.form.intervalWeek || 1,
      pickupRepeatDays: this.form.repeatDays
        .map(day => configDay(day))
        .join(", "),
      timePickup: this.form.time
        ? `${formatDate(this.form.time, "HH:mm")} ${
            configDates(this.form.cityTimezone).id
          }`
        : "-"
    };
  }

  get id() {
    return this.$route.params.id;
  }

  get pickupTimeLabel(): string {
    return `Barang dipickup tanggal ${formatDateNormal(
      this.startDate,
      "DD MMMM YYYY"
    )} - ${this.form.time}`;
  }

  mounted(): void {
    this.fetchClientOptions("");
    this.fetchVehicles("");

    if (this.isEditPage) {
      this.fetchDetail();
    } else {
      if (this.isClientBranch) {
        this.setAutofill(
          this.accountController.accountData.account_type_detail.id
        );
      }
    }
  }

  fetchDetail(): void {
    this.apiDetailData.loading = true;
    this.controller
      .getDetailPickupSchedule(this.id)
      .then(async res => {
        this.startDate = res.pickupScheduleNextRun;
        this.fetchClientOptions(res.pickupScheduleClientName);
        this.apiDetailData.loading = true;
        await this.fetchDistricts(res.pickupScheduleDistrictName, "", 100)
          .then(districtRes => {
            const resp = districtRes as DistrictData[];
            const districtId = resp.find(
              district => district.code === res.pickupScheduleDistrict
            );
            this.form.districtId = new OptionsClass({
              name: districtId?.districtNameCityName,
              value: districtId?.code,
              code: districtId?.cityCode
            });
          })
          .finally(() => {
            this.apiDetailData.loading = false;
          });

        const clientId = new OptionsClass({
          name: res.pickupScheduleClientName,
          value: res.pickupScheduleClientId.toString(),
          code: this.accountController.accountData.account_location.city_code
        });
        this.letterCodeOptions = [
          new OptionsClass({
            name: res.pickupScheduleCityId,
            value: res.pickupScheduleCityId
          })
        ];
        this.form = {
          ...this.form,
          clientId: clientId,
          address: res.pickupScheduleAddress,
          cityId: res.pickupScheduleCityId,
          cityTimezone: res.pickupScheduleCityTimezone,
          courierNote: res.pickupScheduleCourierNote,
          end: res.pickupScheduleEnd,
          start: res.pickupScheduleStart,
          estimateTotalKoli: res.pickupScheduleEstTotalKoli.toString(),
          estimateTotalTonase: res.pickupScheduleEstTotalTonase.toString(),
          geoloc: res.pickupScheduleGeoloc,
          intervalWeek: res.pickupScheduleIntervalWeek,
          name: res.pickupScheduleName,
          repeatDays: res.pickupScheduleRepeatDays.split(","),
          serviceCode: res.pickupScheduleServiceCodes.split(","),
          time: res.pickupScheduleTime,
          transportType: res.pickupScheduleTransportType
        };
      })
      .catch(error => {
        this.apiDetailData.errorType =
          parsingErrorResponse(error).type === "internet-error"
            ? "internet"
            : parsingErrorResponse(error).type;
      })
      .finally(() => {
        this.apiDetailData.loading = false;
      });
  }

  fetchClientOptions(search: string): void {
    if (this.isAdmin) {
      this.clientController._onGetCLientMappingList({
        isBranch: true,
        isParent: false,
        limit: 10,
        page: 1,
        search
      });
    }

    if (this.isClientParent) {
      this.clientController._onGetList({
        clientParentId: this.accountController.accountData.account_type_detail
          .id,
        limit: 10,
        page: 1,
        search,
        status: ""
      });
    }
  }

  fetchDistricts(search: string, cityCode = "", limit = 10) {
    return new Promise(resolve => {
      this.locationController
        .getDistrictList({
          page: 1,
          limit: limit,
          search,
          status: "active",
          cache: true,
          cityCode: cityCode,
          excludeCountry: "",
          type: ""
        })
        .then(res => resolve(res));
    });
  }

  fetchVehicles(search = "") {
    this.courierManagementController
      .getListVehicle(new ApiRequestCourierVehicleList({ search }))
      .then(res => {
        this.transportTypeOptions = res.data.map(
          (item: { id: number; vehicle_name: string }) => {
            return new OptionsClass({
              name: item.vehicle_name,
              value: item.vehicle_name
            });
          }
        );
        this.transportTypeOptions.unshift(
          new OptionsClass({ name: "Pilih tipe armada", value: "" })
        );
      });
  }

  onSearchDistricts = debounce((search: string) => {
    this.fetchDistricts(search);
  }, 500);

  onSearchClient = debounce((search: string) => {
    this.fetchClientOptions(search);
  }, 500);

  onSelectDistrict(item: OptionsClass): void {
    if (item.value) {
      this.form.cityTimezone = item.code || "";
    } else {
      this.form.cityTimezone = "";
    }
  }

  async onSelectClient(value: OptionsClass) {
    if (value?.value) {
      // set autofill
      this.setAutofill(Number(value.value));
    } else {
      this.form.cityId = "";
      this.letterCodeOptions = [new OptionsClass({ name: "-", value: "" })];
    }
  }
  onConfirmEdit(): void {
    this.showModalConfirm = true;
  }

  onBack(): void {
    if (this.isEditPage) {
      this.$router.push(`/pickup/scheduled-pickup/${this.id}`);
    } else {
      this.$router.push("/pickup/schedule-list");
    }
  }

  async onSubmit(): Promise<void> {
    this.showModalFailed = false;
    this.showModalSuccess = false;
    this.showModalConfirm = false;
    const parentId = this.isClientParent
      ? this.accountController.accountData.account_type_detail.id
      : 0;
    const payload = new PickupScheduleFormApiRequest({
      psName: this.form.name,
      psClientId: Number(this.form.clientId.value),
      psClientParentId: parentId,
      psDistrictId: this.form.districtId.value,
      psCityId: this.form.cityId,
      psAddress: this.form.address,
      psGeoloc: this.form.geoloc,
      psCourierNote: this.form.courierNote,
      psTransportType: this.form.transportType,
      psServiceCodes: this.form.serviceCode.join(","),
      psStart: formatDateNormal(this.form.start, "YYYY-MM-DD"),
      psEnd: this.form.end ? formatDateNormal(this.form.end, "YYYY-MM-DD") : "",
      psIntervalWeek: this.form.intervalWeek || 1,
      psRepeatDays: this.form.repeatDays.join(","),
      psEstimateTotalKoli: Number(this.form.estimateTotalKoli),
      psEstimateTotalTonase: Number(this.form.estimateTotalTonase),
      psTime: this.form.time,
      psCityTimezone: this.form.cityTimezone
    });

    try {
      MainAppController.showLoading();
      this.isLoadingSubmit = true;
      if (this.isEditPage) {
        await this.controller.updatePickupSchedule({
          payload,
          id: Number(this.id)
        });
      } else {
        await this.controller.createPickupSchedule(payload);
      }
      this.modalMessageEntities = {
        ...this.modalMessageEntities,
        image: "badge-success"
      };
      this.showModalSuccess = true;
    } catch (error) {
      const message = error.response.data.message;
      this.modalErrorMessageEntities = new ErrorMessageEntities({
        type: CLIENT_ERROR,
        title: `"${this.form.clientId.name}" gagal ${
          this.isEditPage ? "diperbarui" : "dibuat"
        }`,
        message:
          typeof message === "string"
            ? "Permintaan konfigurasi jadwal baru Pick Up gagal dibuat. Cek & coba kembali."
            : message.id,
        onTryAgain: this.onSubmit,
        isFailed: true,
        buttonSuccessText: "Ulangi"
      });
      this.showModalFailed = true;
    } finally {
      this.isLoadingSubmit = false;
      MainAppController.closeLoading();
    }
  }

  onCloseModalSucces(): void {
    this.showModalSuccess = false;
    this.$router.push("/pickup/schedule-list");
  }

  async setAutofill(clientId: number): Promise<void> {
    try {
      // get detail client
      this.isLoadingAutofill = true;
      const clientDetail = await this.clientController.fetchClientDetail(
        clientId
      );
      const districtName = `${clientDetail.clientDistrictName}, ${clientDetail.clientCityName}`;
      // set 3lc options
      this.letterCodeOptions = [
        new OptionsClass({
          name: clientDetail.clientCityCode,
          value: clientDetail.clientCityCode
        })
      ];
      const districtResp: DistrictData[] = (await this.fetchDistricts(
        clientDetail.clientDistrictName,
        "",
        100
      )) as DistrictData[];
      // autofill 3lc, address & district
      this.form = {
        ...this.form,
        address: clientDetail.clientAddress,
        cityId: clientDetail.clientCityCode,
        districtId: new OptionsClass({
          name: districtName,
          value: clientDetail.clientDistrictCode,
          code: districtResp[0].timezone
        }),
        clientId: new OptionsClass({
          name: clientDetail.clientName,
          value: clientDetail.clientId.toString()
        }),
        cityTimezone: districtResp[0].timezone
      };
    } catch (error) {
      this.isLoadingAutofill = false;
    } finally {
      this.isLoadingAutofill = false;
    }
  }

  onSelectStartDate(): void {
    this.startDate = this.form.start;
  }

  get disabledBosspack() {
    return ((new Date().getHours() === 14 && new Date().getMinutes() > 0) || (new Date().getHours() > 14))
  }
}
